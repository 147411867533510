import React from "react";
import titleCase from "../../utils/SnakeToTitleCase";
import statusBadgeClass from "./StatusBadgeClass";

const WhollyOwnedApplicationApprovalControls = (props) => {
  const updateWOApplicationStatus = (status) => {
    const url = `/wholly_owned_application/${props.application.id}`;
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({ status: status }),
    }).then((result) => {
      window.location.reload();
    });
  };

  return (
    <>
      <div className="dropdown me-1">
        <div className="btn-group">
          <button
            type="button"
            className={"btn btn-" + statusBadgeClass(props.application.status) + (props.application.status == "complete" ? " disabled" : "")}>
            {titleCase(props.application.status)}
          </button>
          {props.application.status != "complete" ? (
            <button
              type="button"
              className={"btn dropdown-toggle dropdown-toggle-split btn-" + statusBadgeClass(props.application.status)}
              id="cpApprovalDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>) : null}
          {props.application.status != "complete" ?
          <ul className="dropdown-menu" aria-labelledby="cpApprovalDropdown">
            {["in_review", "needs_outreach", "complete"].map((status) => (
              status != props.application.status ?
                <li>
                <a className="dropdown-item" onClick={() => {updateWOApplicationStatus(status);}}>
                  {titleCase(status)}
                </a>
              </li> 
              : null
             ))}
          </ul> : null}
        </div>
      </div>
      <div className="dropdown ms-1">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary">
            Downloads
          </button>
          <button
            type="button"
            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
            id="cpApprovalDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-reference="parent">
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="cpApprovalDropdown">
            <li>
              <a
                className="dropdown-item"
                href={`/wholly_owned_application/${props.application.id}/download_agreement`}
                title="Wholly Owned Agreement">
                Wholly Owned Agreement
              </a>
            </li>
            {
              props.documents.map((document) => (
                <li>
                  <a className="dropdown-item" href={document.path}>
                    {document.filename}
                  </a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhollyOwnedApplicationApprovalControls;
