import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import Modal from "../Modal";
import { Button } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import RecordCount from "../utils/RecordCount";

const Ldns = (props) => {
  const [data, setData] = useState(useMemo(() => props.ldn, []));
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalDeleteId, setModalDeleteId] = useState(null);

  var deleteLdn = (id) => {
    const url = `/ldns/${id}`;

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    }).then((result) => {
      if (result.ok) {
        var dataCopy = [...data];
        dataCopy.splice(
          dataCopy.findIndex((obj) => obj.id == id),
          1
        );
        setData(dataCopy);
        setShowModalDelete(false);
      }
      throw new Error("Network error.");
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Manufacturer",
        accessor: "manufacturer_name",
        filter: "fuzzyText",
        Cell: (tableProps) => titleCase(tableProps.row.original.manufacturer_name),
      },
      {
        Header: "Network",
        accessor: "network",
        filter: "fuzzyText",
        Cell: (tableProps) => (<span className="badge bg-secondary">{tableProps.row.original.network}</span>),
      },
      {
        Header: "Covered Entity",
        accessor: "id_340b",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            {tableProps.value ?
            <a href={`/entities/340bid/${tableProps.value}`}>{tableProps.row.original.id_340b}</a>
            :
            <i className="text-muted">None</i>
            }
          </>)
      },
      {
        Header: "Pharmacy ID (PID)",
        accessor: "pid",
        filter: "fuzzyText",
      },
      {
        Header: "Kind",
        accessor: "kind",
        Cell: (tableProps) => (tableProps.row.original.kind ? <span className="badge bg-secondary">{tableProps.row.original.kind}</span> : <i className="text-muted">None</i>)
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.created_by == "System" ? 
            <div className="text-muted">
              <i className="fa fa-cog me-2"></i>
              System
            </div>
            :
            <div className="text-nowrap">
              <i className="fa fa-user me-2"></i>
              {tableProps.row.original.created_by}
            </div>
            }
          </>
        ) 
      },
      {
        Header: "Delete",
        id: "id",
        accessor: (str) => "delete",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
          { props.can_edit ?
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => {
                setShowModalDelete(true);
                setModalDeleteId(tableProps.row.original.id);
              }}>
              <i className="fa fa-trash"></i>
            </Button>
          : "N/A" }
          </>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      { !props.hide_count ? 
        <RecordCount record_count={data.length} />
      : null }
      { data.length > 0 || props.hide_count ? <CustomTable columns={columns} data={data} /> : null }
      <Modal
        show={showModalDelete}
        title="Delete Ldn"
        buttonText="Delete"
        buttonType="danger"
        modalId={`delete_ldn_modal_${modalDeleteId}`}
        body={`Are you sure you want to delete ${data.find((row) => row.id == modalDeleteId)?.network} network, PID:${
          data.find((row) => row.id == modalDeleteId)?.pid
        }, with ${titleCase(data.find((row) => row.id == modalDeleteId)?.manufacturer_name)} manufacturer?`}
        action={() => {
          deleteLdn(modalDeleteId);
          setshowModalDelete(false);
        }}
        dismiss={() => {
          setShowModalDelete(false);
        }}
      />
    </React.Fragment>
  );
};

export default Ldns;
