import React from "react";
import { useState, useMemo, useEffect } from "react";
import CustomTable from "../CustomTable";
import { Button, FormGroup, FormText } from "react-bootstrap";
import Loading from "../utils/Loading";
import RecordCount from "../utils/RecordCount";
import FormModal from "../FormModal";
import { Form } from "react-bootstrap";
import parseAndFormatDate from "../utils/ParseAndFormatDate";

const EffectiveDates = (props) => {
  const [data, setData] = useState(useMemo(() => props.effective_dates, []));
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [modalUserId, setmodalUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data && props.effective_dates_path) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.effective_dates_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);  

  const columns = useMemo(
    () => [
      {
        Header: "Effective Date",
        accessor: "effective_date",
        filter: "fuzzyText",
        Cell: (row) => <a className="badge bg-success text-uppercase"
          onClick={() => {
          setmodalUserId(row.row.original.id);
          setShowModalUpdate(true);
        }}>{row.value}</a>,
      },
      {
        Header: "ID 340B",
        accessor: "covered_entity",
        filter: "fuzzyText",
        Cell: (row) => <a href={`/entities/340bid/${row.value}?tab=effective_dates`}>{row.value}</a>
      },
      { Header: "Pharmacy Name", accessor: "contracted_pharmacy", filter: "fuzzyText" },
      { Header: "Pharmacy ID", accessor: "pid", filter: "fuzzyText" },
      { Header: "NPI", accessor: "npi", filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value || "n/a"}</small>
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        Cell: (row) => <small>{row.value}</small>
      },
      {
        Header: "Kind",
        accessor: "kind",
        Cell: (row) => <span className="badge bg-secondary text-light text-uppercase">{row.value}</span>
      },
      {
        Header: "Actions",
        Filter: () => {},
        Cell: (tableProps) => {
          return (
            <div>
              { props.can_edit ?
              <>
                <Button
                  className="btn btn-primary btn-sm me-2"
                  onClick={() => {
                    setmodalUserId(tableProps.row.original.id);
                    setShowModalUpdate(true);
                  }}>
                  <i className="fa-solid fa-pencil"></i>
                </Button>
                <Button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setmodalUserId(tableProps.row.original.id);
                    setShowModalDelete(true);
                  }}>
                  <i className="fa-solid fa-trash"></i>
                </Button>
              </>
              : "N/A" }
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
        {isLoading ?
          <Loading />
        : data ? <>
          {!props.hide_count ?
            <RecordCount record_count={data.length} />
          : null}
          { data.length > 0 || props.hide_count ? <CustomTable columns={props.columns || columns} data={data} /> : null }
          <FormModal
            show={showModalDelete}
            title="Delete Effective Date"
            buttonText="Delete"
            buttonType="danger"
            modalId={`delete_effective_date_${modalUserId}`}
            fields={`Are you sure you want to delete ${
              data?.find((row) => row.id == modalUserId)?.covered_entity
            }'s PID: ${data?.find((row) => row.id == modalUserId)?.pid}, ${
              data?.find((row) => row.id == modalUserId)?.manufacturer
            } effective date?`}
            action={`/effective_dates/destroy/${modalUserId}`}
            method="POST"
            dismiss={() => {
              setShowModalDelete(false);
            }}
          />
          <FormModal
            show={showModalUpdate}
            title={`Update Effective Date For ${data?.find((row) => row.id == modalUserId)?.contracted_pharmacy}`}
            buttonText="Update"
            buttonType="primary"
            modalId={`update_effective_date_${modalUserId}`}
            fields={
              <>
                <div className="mb-3">
                  <label className="me-2">PID:</label>
                  <br/>
                  <b className="text-primary">{data?.find((row) => row.id == modalUserId)?.pid}</b>
                </div>                
                <div className="mb-3">
                  <label className="me-2">Manufacturer:</label>
                  <br/>
                  <b className="text-primary">{data?.find((row) => row.id == modalUserId)?.manufacturer}</b>
                </div>
              <Form.Group className="mb-3" controlId="date">
                <Form.Label>Effective Date <small className="text-danger">*</small></Form.Label>
                <Form.Control required type="date" name="date" defaultValue={data?.find((row) => row.id == modalUserId)?.date} />
              </Form.Group>
              </>
            }
            action={`/effective_dates/${modalUserId}/update`}
            method="POST"
            dismiss={() => {
              setShowModalUpdate(false);
            }}
          />                    
        </>
        : null }
    </React.Fragment>
  );
};

export default EffectiveDates;
