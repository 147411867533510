import React, { useEffect } from "react";
//import verifyImage from "images/verify_account_illustration.png";

export default function Modal(props) {
  useEffect(() => {
    if (props.show) {
      var modalElement = document.getElementById(props.modalId);
      var modalObject = new bootstrap.Modal(modalElement, {
        keyboard: false,
        backdrop: "static",
      });
      modalObject.show();
    } else {
      if (modalObject) {
        modalObject.hide();
        modalObject.dispose();
      }
    }
  });

  if (props.show) {
    return (
      <div className="modal fade" id={props.modalId} role="dialog">
        <div className={`modal-dialog ${props.modalSize == "large" ? "modal-lg" : null}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.dismiss();
                }}></button>
            </div>
            <div className="modal-body">
              {props.body}
            </div>
            {props.disableFooter ? null : (
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn btn-${props.buttonType ?? "primary"}`}
                  data-bs-dismiss="modal"
                  onClick={() => props.action()}>
                  {props.buttonText}
                </button>
                <button type="button" class="btn bg-white border" data-bs-dismiss="modal" onClick={() => props.dismiss()}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
