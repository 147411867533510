import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import { Button, Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import statusBadgeClass from "./WhollyOwnedApplication/StatusBadgeClass";
import RecordCount from "../utils/RecordCount";

const WhollyOwnedRecertifications = (props) => {
  const [data, setData] = useState(useMemo(() => props.wholly_owned_recertifications, []));

  function statusBadge(status) {
    status = titleCase(status);
    return <span className={`badge fw-normal text-uppercase bg-` + statusBadgeClass(status)}>{titleCase(status)}</span>;
  }

  const columns = useMemo(
    () => [
      {
        Header: "340B ID",
        accessor: "id_340b",
        filter: "fuzzyText",
        Cell: (row) => <a href={`/entities/340bid/${row.value}`}>{row.value}</a>
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer_name",
        filter: "fuzzyText",
        Cell: (row) => <small>{row.value}</small>
      },
      {
        Header: "Contact",
        accessor: (row) => `${row.contact_email} ${row.contact_first_name} ${row.contact_last_name}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div>
            <div>{tableProps.row.original.contact_first_name} {tableProps.row.original.contact_last_name}</div>
            <small className="text-muted">{tableProps.row.original.contact_email}</small>
          </div>
        )
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
      {
        Header: "Pharmacies",
        Cell: (tableProps) => (
          <>
          <small className="badge bg-success text-light" title="Attested pharmacies">{tableProps.row.original.pharmacy_ids_attested.length}</small> / 
          <small className="badge bg-danger text-light" title="Rejected pharmacies">{tableProps.row.original.pharmacy_ids_rejected.length}</small>
          </>
        )
      },
      {
        Header: "Documents",
        id: "id",
        accessor: (str) => "view",
        Filter: () => {},
        Cell: (tableProps) => (
          <div className="dropdown ms-1">
          <div className="btn-group btn-group-sm">
            <button
              type="button"
              className="btn btn-secondary btn-sm">
              <i className="fa fa-save"></i> {tableProps.row.original.documents.length}
            </button>
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
              id="downloadsDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="downloadsDropdown">
              {
                tableProps.row.original.documents.map((document) => (
                  <li>
                    <a className="dropdown-item" href={document.path}>
                      {document.filename}
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>  
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="pt-3 pb-2 row">
        <div className="col-12">
          <h1 className="h2">
            <i className="fa-solid fa-certificate pe-2"></i>
            Recertification
            {props.status ? <small className="ms-3">{statusBadge(props.status)}</small> : null}
          </h1>
          <p className="text-muted">Covered entity requests to re-certify wholly owned designations for 340B pricing eligibility. </p>
        </div>
      </div>
      {!props.hide_count ?
        <RecordCount record_count={data.length} />
      : null}
      <CustomTable columns={columns} data={data} />
    </React.Fragment>
  );
};

export default WhollyOwnedRecertifications;
