import React from "react";
import { useMemo } from "react";
import CustomTable from "../CustomTable";

const EligibilityQcReport = (props) => {

  const activityColumns = useMemo(
    () => [
      { 
        Header: "Item Type", 
        accessor: "item_type",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "Manufacturer", 
        accessor: "manufacturer",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "Pharmacy ID", 
        accessor: "pharmacy_id",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "340B ID", 
        accessor: "id_340b",
        Cell: (row) => <a href={`/entities/340bid/${row.value}`}>{row.value}</a>
      },
      { 
        Header: "Update(s)", 
        accessor: "update_type",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "Updated At", 
        accessor: "updated_at",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "Prev Value", 
        accessor: "prev_value",
        Cell: (row) => <small>{row.value}</small>
      },
      { 
        Header: "New Value", 
        accessor: "new_value",
        Cell: (row) => <small>{row.value}</small>
      },
      {
        Header: "User",
        accessor: "user",
        Cell: (row) => <small>{row.value}</small>
      }
    ],
    []
  );    


  return (
    <React.Fragment>
      <CustomTable data={props.activities} columns={activityColumns} />
    </React.Fragment>
  );
};

export default EligibilityQcReport;
