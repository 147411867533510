import React from "react";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";

export default function NewDesignationForm(props) {
  const [searchPid, setSearchPid] = useState("");
  const [search340bId, set340bId] = useState(props.id_340b || "");
  const [hin, setHin] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [coveredEntity, setCoveredEntity] = useState("");

  useEffect(() => {
    if (search340bId.length > 2 || searchPid.length > 2) {
      fetch(`/api/form_validations/id_340b_and_pharmacy_id?pharmacy_id=${searchPid}&id_340b=${search340bId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.response_code == "201") {
            // Set covered entity if found.
            // Set pharmacy if found.
            // Auto fill HIN if pharmacy is found.
            setCoveredEntity(data.covered_entity);
            if (data.pharmacy_id) {
              setHin(data.hin);
              setPharmacyId(data.pharmacy_id);
            } else {
              // Clear HIN and pharmacy if no pharmacy is found.
              setHin(null);
              setPharmacyId(null);
            }
          } else {
            if (!data.covered_entity) {
              // Clear all state fields if there is no matching covered entity.
              setCoveredEntity(null);
            }
            setHin(null);
            setPharmacyId(null);
          }
        });
    }
  }, [searchPid, search340bId]);

  const handleOnChange = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3" controlId="id_340b">
        <Form.Label>
          340B ID
          {coveredEntity ? (
            <small className="text-success ms-1">{coveredEntity}</small>
          ) : (
            <small className="text-danger ms-1">Not found</small>
          )}
        </Form.Label>
        <Form.Control
          required
          type="text"
          name="designation[id_340b]"
          placeholder="Enter 340B ID..."
          onBlur={(e) => {
            set340bId(e.target.value);
            handleOnChange(e);
          }}
          defaultValue={props.id_340b}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="manufacturer_name">
        <Form.Label>Manufacturer</Form.Label>
        <Form.Select required name="designation[manufacturer_name]">
          <option value="">{"< Make a Selection >"}</option>
          {props.manufacturers.map((manufacturer) => (
            <option key={manufacturer.key} value={manufacturer.key}>
              {manufacturer.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="pid">
        <Form.Label>
          HRSA Pharmacy ID
          {pharmacyId ? (
            <small>
              <i className="fa fa-check text-success ms-1"></i>
            </small>
          ) : (
            <small className="text-danger ms-1">Not found</small>
          )}
        </Form.Label>
        <Form.Control
          required
          type="text"
          name="designation[pid]"
          placeholder="Enter HRSA Pharmacy ID..."
          onBlur={(e) => {
            setSearchPid(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="health_industry_number">
        <Form.Label>
          HIN
          {hin ? (
            <small>
              <i className="fa fa-check text-success ms-1"></i>
            </small>
          ) : null}
        </Form.Label>
        <Form.Control
          type="text"
          name="designation[health_industry_number]"
          placeholder="Enter health industry number..."
          defaultValue={hin}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="kind">
        <Form.Label>Kind</Form.Label>
        <Form.Select required name="designation[kind]">
          <option value="">{"< Make a Selection >"}</option>
          {props.designation_kinds.map((kind) => (
            <option key={kind} value={kind}>
              {titleCase(kind)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="flag">
        <Form.Label>Flag</Form.Label>
        <Form.Control readOnly type="text" name="designation[flag]" value={"exception"} placeholder="Enter flag..." />
      </Form.Group>
    </React.Fragment>
  );
}
