export default function parseAndFormatDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const day = date.getDate();

    const formattedDate = `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}-${year}`;

    return formattedDate;
  };