import React from "react";
import { Button, Form } from "react-bootstrap";
import snakeToTitleCase from "./utils/SnakeToTitleCase"

const SubmissionRowSearch = (props) => {
  return (
    <React.Fragment>
      <div className="pt-3 pb-1">
        <h1 className="h2">
          <i className="fa fa-envelopes-bulk pe-2"></i>
          Submission Claims
        {props.params?.manufacturer ?
            <div className="badge text-muted bg-light ms-2 fw-normal">{snakeToTitleCase(props.params.manufacturer)}</div>
          : null}
        </h1>
      </div>
      <p className="text-muted">Submitted covered entity claims of contract pharmacy transactions eligible for 340B pricing.</p>
      <div className="jumbotron p-4 mb-3 bg-body-tertiary rounded-4">
        <Form method="GET">
          <div className="row">
            <Form.Group controlId="id340b" className="col-sm-4">
              <Form.Label>340B ID <small className="text-danger">* Required</small></Form.Label>
              <Form.Control required className="input-lg" type="text" placeholder="Enter 340B ID..." name="id_340b" defaultValue={props.params.id_340b} autofocus="true" />
            </Form.Group>
            <Form.Group controlId="manufacturer" className="col-sm-4">
              <Form.Label>Manufacturer</Form.Label>
              <Form.Select type="select" className="select-lg" placeholder="Manufacturer" name="manufacturer">
                <option value="">All Manufacturers</option>
                {props.manufacturers.map((manufacturer) => (
                  <option key={manufacturer} value={manufacturer} selected={manufacturer == props.params.manufacturer ? "selected" : null}>
                    {snakeToTitleCase(manufacturer)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-sm-4">
              <br/>
              <Button className="mt-2" variant="primary" type="submit">
                Search Submission Claims
              </Button>
            </Form.Group>
          </div>
        </Form>
        </div>
    </React.Fragment>
  );
};

export default SubmissionRowSearch;
