import React from "react";
import { Form, Button } from "react-bootstrap";

export default function SearchForm(props) {

  return (
    <>
    <div className="jumbotron p-4 mb-3 bg-body-tertiary rounded-4">
      <Form method="GET">
        <div className="row">
      <Form.Group className="col mb-3" controlId="id_340b">
        <Form.Label>
          340B ID
        </Form.Label>
        <Form.Control className="form-control-sm" type="text" name="id_340b" placeholder="Enter 340B ID..." defaultValue={props.search_params.id_340b} autoFocus={true} />
      </Form.Group>
      <Form.Group className="col mb-3" controlId="pid">
        <Form.Label>
          Pharmacy ID
        </Form.Label>
        <Form.Control className="form-control-sm" type="text" name="pharmacy_id" placeholder="Enter Pharmacy ID..." defaultValue={props.search_params.pharmacy_id} />
      </Form.Group>
      <Form.Group className="col mb-3" controlId="pharmacy_name">
        <Form.Label>
          Pharmacy Name
        </Form.Label>
        <Form.Control className="form-control-sm" type="text" name="pharmacy_name" placeholder="Enter Pharmacy Name..." defaultValue={props.search_params.pharmacy_name} />
      </Form.Group>
      </div>
      <div className="row">
      <Form.Group className="col" controlId="manufacturer_name">
        <Form.Label>Manufacturer</Form.Label>
        <Form.Select className="form-select-sm" name="manufacturer_name" defaultValue={props.search_params.manufacturer_name} >
          <option value="">{"< All Manufacturers >"}</option>
          {props.manufacturers.map( (manufacturer) =>
            <option key={manufacturer.key} value={manufacturer.key}>{manufacturer.name}</option>
          )}
        </Form.Select>
      </Form.Group>
      <Form.Group className="col" controlId="health_industry_number">
        <Form.Label>
          Health Industry Number (HIN)
        </Form.Label>
        <Form.Control className="form-control-sm" type="text" name="health_industry_number" placeholder="Enter health industry number..." defaultValue={props.search_params.health_industry_number} />
      </Form.Group>
      <Form.Group className="col" controlId="include_deleted">
        <Form.Check type="switch" value="true" id="include_deleted" name="include_deleted" className="mb-2 text-muted fst-italic" label="Include deleted records" 
          defaultChecked={props.search_params.include_deleted}></Form.Check>
        <Button type="submit" className="btn btn-primary btn-sm">
          <i className="fa fa-search me-1"></i>
          Search All Designations
        </Button>
      </Form.Group>
      </div>
    </Form>
    </div>
    </>    
  )
}

