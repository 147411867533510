import React from "react";
import { useState, useMemo, useEffect } from "react";
import CustomTable from "./CustomTable";
import { Button } from "react-bootstrap";
import parseAndFormatDate from "./utils/ParseAndFormatDate";
import snakeToTitleCase from "./utils/SnakeToTitleCase";
import { currencyWithCommas } from "./utils/NumberFormatter";
import Loading from "./utils/Loading";
import RecordCount from "./utils/RecordCount";

const SubmissionRows = (props) => {
  const [data, setData] = useState(props.submission_rows || null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data && props.submission_rows_path) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.submission_rows_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);  

  const formatAddress = (pharmacy) => {
    if (!pharmacy) return "";

    var address2 = pharmacy.pharmacy_address2 ? ` ${pharmacy.pharmacy_address2} ` : "";
    return `${pharmacy.pharmacy_address1 + address2} ${pharmacy.pharmacy_city} ${pharmacy.pharmacy_state} ${
      pharmacy.pharmacy_zip
    }`;
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID 340B",
        accessor: "submission_row.id_340b",
        filter: "fuzzyText",
        Cell: (row) => (
          <a href={`/entities/340bid/${row.value}`}>{row.value}</a>
        )
      },
      {
        Header: "Date",
        accessor: "submission_row.created_at",
        filter: "fuzzyText",
        Cell: (props) => (
          <small className="text-muted">{parseAndFormatDate(props.row.original.submission_row.created_at)}</small>
        )
      },
      {
        Header: "Manufacturer",
        accessor: "submission_row.manufacturer",
        filter: "fuzzyText",
        Cell: (props) => (
          snakeToTitleCase(props.row.original.submission_row.manufacturer)
        )
      },
      {
        Header: "NDC",
        accessor: "submission_row.ndc",
        filter: "fuzzyText",
        Cell: (props) => (
          <small className="text-muted">{props.row.original.submission_row.ndc}</small>
        )
      },
      {
        Header: "Quantity",
        accessor: "submission_row.quantity",
      },
      {
        Header: "Prescriber Id Qualifier",
        accessor: "submission_row.prescriber_id_qualifier",
      },
      {
        Header: "Prescriber Id",
        accessor: "submission_row.prescriber_id",
        Cell: (props) => (
          <small className="text-muted">{props.row.original.submission_row.prescriber_id}</small>
        )
      },
      {
        Header: "Service Provider Id Qualifier",
        accessor: "submission_row.service_provider_id_qualifier",
      },
      {
        Header: "Service Provider Id",
        accessor: "submission_row.service_provider_id",
      },
      {
        Header: "Contracted Entity Id",
        accessor: "submission_row.contracted_entity_id",
      },
      {
        Header: "Staging",
        accessor: "submission_row.staging",
      },
      {
        Header: "Claim Conforms Flag",
        accessor: "submission_row.claim_conforms_flag",
        Cell: (row) => <span className="badge bg-light text-dark">{row.value == true ? "TRUE" : "FALSE"}</span>
      },
      {
        Header: "Network",
        accessor: "submission_row.network",
        Cell: (props) => (
          <div className="badge bg-secondary text-uppercase">{props.row.original.submission_row.network}</div>
        )
      },
      {
        Header: "DEA",
        accessor: "submission_row.dea",
      },
      {
        Header: "Pharmacy Id",
        accessor: "submission_row.pharmacy_id",
        Cell: (props) => (
          <small className="text-muted">{props.row.original.submission_row.pharmacy_id}</small>
        )
      },
      {
        Header: "Pharmacy Name",
        accessor: "pharmacy.pharmacy_name",
      },
      {
        Header: "Pharmacy Address",
        accessor: "pharmacy.pharmacy_address",
        Cell: (props) => {
          return formatAddress(props.row.original.pharmacy);
        },
      },
      {
        Header: "Chain Name",
        accessor: "submission_row.chain_name",
        Cell: (props) => (
          <b>{props.row.original.submission_row.chain_name}</b>
        )
      },
      {
        Header: "Product Name",
        accessor: "submission_row.product_name",
      },
      {
        Header: "WAC Price",
        accessor: "submission_row.wac_price",
        Cell: (props) => (
          currencyWithCommas(props.row.original.submission_row.wac_price)
        )
      },
      {
        Header: "Submitted By",
        accessor: "submission_row.submitted_by",
        filter: "fuzzyText",
        Cell: (props) => (
          <small className="text-muted">{props.row.original.submission_row.submitted_by}</small>
        )
      }
    ],
    []
  );

  return (
    <React.Fragment>
      {isLoading ?
        <Loading />
      : data ?
        <>
        {!props.hide_count ?
          <RecordCount record_count={data.length} record_type="submission claim" />
        : null}
        { data.length > 0 ?
          <>
          <div className="pb-2 mb-2">
            <small className="text-muted">340B ID:</small> <b>{props.id_340b}</b>
            <small className="text-muted ms-2">MANUFACTURER:</small> <b>{props.manufacturer}</b>
            { props.show_download ?
            <a
              href={`/submission_rows/download?id_340b=${props.id_340b}&manufacturer=${props.manufacturer}`}
              download={`submission_rows.xlsx`}
              target="_blank"
              className="btn btn-sm btn-primary ms-2">
                <i className="fa fa-download me-1"></i> Download Search Results
            </a>
            : null }
          </div>
        <CustomTable columns={columns} data={data} />
        </> : null }
      </>: null }
    </React.Fragment>
  );
};

export default SubmissionRows;
